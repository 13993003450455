/* Utility: promo design block
-------------------------------------------------- */

@keyframes toaster-right-exit {
    from { right: 0;}
      to { right: -9999px; }  
}

@keyframes toaster-right {
    from { right: -9999px;}
      to { right: 0; } 
}

@keyframes toaster-left-exit {
    from { left: 0;}
      to { left: -9999px; } 
}

@keyframes toaster-left {
   from { left: -9999px;}
      to { left: 0; } 
}

@keyframes left-exit {
    100%  {left: -9999px; }
}

@keyframes left {
    100% { left: 0; }
}

@keyframes right-exit {
    100% { right: -9999px; }
}

@keyframes right {
    100% { right: 0; }
}

@keyframes top-exit {
    from { top: 0;}
      to { top: -9999px; } 
}

@keyframes top {
    100% { top: 0; }
}

@keyframes bottom-exit {
    from { bottom: 0;}
      to { bottom: -9999px; } 
}

@keyframes bottom {
    100% { bottom: 0; }
}

@keyframes center-exit {
    from { opacity: 1;}
      to { opacity: 0; } 
}

@keyframes center {
    from { opacity: 0;}
      to { opacity: 1; } 
}

#promo-designer-modal-custom-pop.toaster_left.visible,
#promo-designer-modal-custom-pop.toaster_right.visible{
    visibility: hidden !important;
}

.promo-designer-modal .modal-dialog.toaster_right,
.promo-designer-modal .modal-dialog.toaster_left{
    visibility: hidden; 
    position: fixed; 
    top:25%;
}

.promo-designer-modal.visible .modal-dialog.toaster_right {
    -webkit-animation: toaster-right 0.5s forwards;
    animation: toaster-right 0.5s forwards;
}

.promo-designer-modal.visible.exit .modal-dialog.toaster_right {
    -webkit-animation: toaster-right-exit 0.5s forwards;
    animation: toaster-right-exit 0.5s forwards;
}

.promo-designer-modal.visible .modal-dialog.toaster_left {
    -webkit-animation: toaster-left 0.5s forwards;
    animation:  toaster-left 0.5s forwards;
    margin: 0;
}

.promo-designer-modal.visible.exit .modal-dialog.toaster_left {
    -webkit-animation: toaster-left-exit 0.5s backwards;
    animation: toaster-left-exit 0.5s backwards;    
}

#promo-designer-modal-custom-pop.visible .modal-dialog.left {
    -webkit-animation: left  1s forwards;  
    animation: left  1s forwards;
    left: -999px; 
}

#promo-designer-modal-custom-pop.visible.exit .modal-dialog.left {
    -webkit-animation: left-exit  1s backwards;
    animation: left-exit  1s backwards;
}

#promo-designer-modal-custom-pop.visible .modal-dialog.right {
    -webkit-animation: right  1s forwards;
    animation: right  1s forwards;
    right: -999px;        
}

#promo-designer-modal-custom-pop.visible.exit .modal-dialog.right {
    -webkit-animation: right-exit  1s backwards;
    animation: right-exit  1s backwards;
}

#promo-designer-modal-custom-pop.visible .modal-dialog.top {
    -webkit-animation: top  1s forwards;
    animation: top  1s forwards;
    top: -999px;        
}

#promo-designer-modal-custom-pop.visible.exit .modal-dialog.top {
    -webkit-animation: top-exit  1s backwards;
    animation: top-exit  1s backwards;
}

#promo-designer-modal-custom-pop.visible .modal-dialog.bottom {
    -webkit-animation: bottom 1s forwards;
    animation: bottom  1s forwards;
    bottom: -999px;        
}

#promo-designer-modal-custom-pop.visible.exit .modal-dialog.bottom {
    -webkit-animation: bottom-exit  1s backwards;
    animation: bottom-exit  1s backwards;
}

#promo-designer-modal-custom-pop.visible.exit .modal-dialog.centered {
    -webkit-animation: center-exit  1s forwards;  
    animation: center-exit  1s forwards;
}

#promo-designer-modal-custom-pop.visible .modal-dialog.centered {
    -webkit-animation: center  1s forwards;  
    animation: center  1s forwards;
}

#promo-designer-modal-custom-pop{ 
    overflow: inherit;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
}

#promo-designer-modal-custom-pop.visible .modal-dialog {
    visibility: visible;
    opacity: 1;
    overflow: auto;
    -webkit-animation-delay: 0;
    animation-delay: 0;    
}

#promo-designer-modal-custom-pop .panel {
    box-shadow: none;
    margin-bottom: 0;
    border-radius: 6px;
}

.promo-design .panel {
    box-shadow: none;
    position: relative;
}

.promo-design .headline,
.promo-design .subheadline {
    font-weight: bold;
    text-align: center;
    padding-bottom: 20px;
}

.promo-design button.close {
    position: absolute;
    right: 0px;
    z-index: 99;
    opacity: .8;
    font-size: 24px;
}

#promo-designer-modal-custom-pop .card-bg-color {
    border-radius: 6px;
}

.promo-designer-modal .modal-content {
    background-color: transparent;
    box-shadow: none;
    border: none;
}

.promo-design .headline.small {
    font-size: 25px;
    line-height: 25px;
}

.promo-design .headline.medium {
    font-size: 45px;
    line-height: 45px;
}

.promo-design .headline.large {
    font-size: 65px;
    line-height: 65px;
}

.promo-design p.text {
    text-align: center;
    padding-bottom: 20px;
}

.promo-design .light {
    color: white;
}

.promo-design .info-text {
    text-align: center;
    font-size: 12px;
    margin-top: 20px;
}

.promo-design .image {
    margin: 0 auto;
    position: relative;
}

.promo-design .image.small {
    width: 50%;
}

.promo-design .image.medium {
    width: 65%;
}

.promo-design .image.large {
    width: 85%;
}

.promo-design .image {
    padding-top: 20px;
    padding-bottom: 20px;
}

.promo-design .text-shadow {
    text-shadow: 0 1px 3px rgba(0,0,0,.6)
}

.promo-design .sticker {
    width: 100px;
    z-index: 1;
    position: relative;
    margin: 0px auto -54px auto;
    font-size: 70px;
    text-align: center;
    vertical-align: middle;
    padding-top: 0px;
    height: 80px;
}

.promo-design .sticker .jp-svg {
    vertical-align: baseline;
}

.promo-design .sticker.rounded,
.promo-design .rounded_rectangle div,
.promo-design .full-width-container {
     border-radius: 10px;
}

.promo-design .full-width-container img {
    width: 100%;
}

.promo-design .rounded_rectangle .full-width-container img {
    border-radius: 5px 5px 0 0; 
}

.promo-design .sticker.circle {
    border-radius: 50px;
    height: 100px;
    padding-top: 20px;
}

.promo-design .promo-designer-wrapper{
    padding-top: 0px;
}

.promo-design .sticker.circle .jp-svg {
    vertical-align: top;
}

.promo-design .sticker.circle {
    padding-top: 10px;
}

.promo-design .card-bg-color.circle .headline.large {
    font-size: 35px;
    line-height: 35px;
}

.promo-design .card-bg-color.circle .headline.medium {
    font-size: 30px;
    line-height: 30px;
}

.promo-design a,
.promo-design .headline,
.promo-design input,
.promo-design p,
.promo-design button{
    text-overflow: ellipsis;
    overflow: hidden;
}

.promo-design .card-bg-color.circle .headline.small {
    font-size: 25px;
    line-height: 25px;
}

.promo-design .card-bg-color.circle .headline,
.promo-design .card-bg-color.circle p.text {
    padding-bottom: 10px;
}

.promo-design p.info-text {
    margin-top: 10px;
}

.promo-design:before,
.promo-design:after {
    box-sizing: border-box;
}

.promo-design a.manage {
    color: #000 !important;
    text-shadow: none;
}

.promo-design .mailinglist-container.light .mailinglist-item{
    color: #fff !important;
    text-shadow: none;
}

.promo-design .mailinglist-container.dark .mailinglist-item {
    text-shadow: none;
}

.promo-design .mailinglist-container.text-shadow .mailinglist-item{
    text-shadow: 0 1px 3px rgba(0,0,0,.6)
}

.promo-show {
    display:flex !important;
    background: rgba(0, 0, 0, 0.6) !important;
}

#promo-designer-modal-custom-pop.promo-designer-modal {
    display: flex;
    display: -ms-flexbox;
    height: 100%;
    visibility: hidden;
}

#promo-designer-modal-custom-pop.visible{ 
    display: flex;
    display: -ms-flexbox;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    visibility: visible;
}

#promo-designer-modal-custom-pop .modal-dialog.modal-md {
    margin: auto;
}

.promo-design .promo-design-button {
    display: block;
    margin: 10px auto;
    text-align: center;
}

.promo-design .mailinglist-container .mailinglist-item {
    border-radius: 0;
}

.promo-designer-modal .modal-body {
    padding: 0 !important;
}

.promo-design .lead-in {
    text-align: center;
    padding-bottom: 10px;
    font-size: 18px;
}

/* Promo card circle */
#promo-designer-modal-custom-pop .promo-design.card-circle .panel {
    border-radius: 50%;
    overflow: hidden;
}

#promo-designer-modal-custom-pop .promo-design.card-circle .card-bg-color.circle {
    align-items: center;
    justify-content: center;
}

#promo-designer-modal-custom-pop .promo-design.card-circle .circle button.close {
    color: #fff;
    opacity: 0.8;
    right: 0px;
    position: absolute;
}

#promo-designer-modal-custom-pop .promo-design.card-circle .circle button.close .tnt-inverse {
    color: #000;
}

#promo-designer-modal-custom-pop .promo-design.card-circle .circle .promo-designer-wrapper {
    margin: 15px auto 0 auto;
      padding-bottom: 25px;
      width: 65%;
}

@media screen and (max-width: 991px) {

   #promo-designer-modal-custom-pop > div  {
       margin-top:50px;
   }

}

@media only screen and (max-width : 1023px) {

    #promo-designer-modal-custom-pop .card.email-signup,
    #promo-designer-modal-custom-pop .modal-dialog.modal-md {
        width: auto !important;
    }

}

@media only screen and (max-width : 768px) {

    #promo-designer-modal-custom-pop .card.email-signup,
    #promo-designer-modal-custom-pop .modal-dialog.modal-md {
        width: 100% !important;
    }

   .promo-design .headline.small {
        font-size: 25px;
        line-height: 25px;
    }

    .promo-design .headline.medium {
        font-size: 35px;
        line-height: 35px;
    }

    .promo-design .headline.large {
        font-size: 45px;
        line-height: 45px;
    }

}

@media only screen and (max-width : 425px) {

    #promo-designer-modal-custom-pop .card.email-signup,
    #promo-designer-modal-custom-pop .modal-dialog.modal-md {
        width: 100% !important;
    }

    .promo-design .headline.small {
        font-size: 20px;
        line-height: 20px;
    }

    .promo-design .headline.medium {
        font-size: 25px;
        line-height: 25px;
    }

    .promo-design .headline.large {
        font-size: 30px;
        line-height: 30px;
    }

}